/* Reservation.module.css */

.reservation-container {
    padding: 20px;
    text-align: center;
    font-family: "Poppins", sans-serif;
}

h2 {
    margin-bottom: 20px;
    font-size: 2rem;
}

.buttons {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
}

.buttons button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
    position: relative;
}

.booking-btn {
    background-color: #003b95;
}

.airbnb-btn {
    background-color: #ff385c;
}

.booking-btn:hover {
    background-color: #002d72;
}

.airbnb-btn:hover {
    background-color: #e02747;
}

.buttons a {
    text-decoration: none;
    color: white;
    display: flex;
    align-items: center;
    gap: 10px;
}

.icon {
    width: 25px;
    height: 25px;
}

/* Responsive Design */
@media (max-width: 768px) {
    h2 {
        font-size: 1.5rem;
    }

    .buttons {
        flex-direction: column;
        gap: 10px;
    }

    .buttons button {
        width: 100%;
        padding: 15px;
    }
}

@media (max-width: 480px) {
    h2 {
        font-size: 1.2rem;
    }

    .buttons {
        flex-direction: column;
        gap: 5px;
    }

    .buttons button {
        width: 100%;
        padding: 12px;
    }
}
