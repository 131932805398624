.accomudationList {
    width: 100%;
}

.accommodation-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center; /* Ensure text is centered */
  }