/* LanguageSelector.css */

.language-selector {
    position: relative;
  }
  
  .selected-language {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .selected-language.open {
    border-bottom: 1px solid #ccc; /* Show border when dropdown is open */
  }
  
  .flag-icon {
    width: 24px;
    height: 24px;
    margin-right: 5px;
    background-size: cover;
  }
  
  .language-options {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    background-color: var(--bg-color);
    background-color: var(--titles-color);
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 5px 5px;
    max-width: 150px; /* Adjust width as needed */
  }
  
  .language-option {
    display: flex;
    align-items: center;
    padding: 0 10px;
    padding-top: 0;
    cursor: pointer;
    gap: 8px;
  }

  .language-option svg{
    
  }
  
  .language-option:hover {
    color: #f0f0f0;
  }
  
  .language-option.active {
    font-weight: bold;
  }

  .language-item {
    display: flex;
    align-items: center;
    gap: 8px; /* Adjust the space between flag and name as needed */
    background-color: var(--bg-title);

  }

  .language-item svg{
    margin-top: 5px;
    /* background-color: green; */
  }

  @media (max-width: 768px) {
    .language-name {
      display: none;
    }

    .language-options {
      width: 100%;
    }
  }