
:root {
  --bg-color: #fff2bf;
  --bg-color-hover: #dcd1a8;
  --titles-color: #664200;
  --text-color: #473800;
  --second-bg-color: #1f0266;
  --second-dark-bg-color: #160147;

}
body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;


}

html {
  scroll-behavior: smooth;

}

.App {
  font-family: "Poppins";

}

/* header {
  background-color: #333;
  color: white;
  padding: 20px;
  text-align: center;
}

header h1 {
  margin: 0;
}

nav ul {
  list-style: none;
  display: flex;
  gap: 1rem;
  justify-content: center;
  padding: 0;
  margin: 0;
}

nav a {
  color: #fff;
  text-decoration: none;
} */

main {
  /* padding: 20px; */
  background-color: var(--bg-color);

}

section {
  /* margin: 20px; */
  padding: 2rem;
  background-color: var(--bg-color);
  border-bottom: 5px solid var(--titles-color);
  margin: 0 10rem;
}

section:last-of-type {
  /* margin: 20px; */
  border-bottom: none;
}

section h2 {
  margin-bottom: 10px;
  color: var(--titles-color);
  text-align: center;
  margin-bottom: 1rem;
}

footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 10px 0;
  position: relative;
  bottom: 0;
  width: 100%;
}

.contact-section {
  padding-bottom: 2rem;
  /* background: #f4f4f4; */
}

.map-container {
  margin-top: 1rem;
}

.carousel .legend {
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
}

@media (max-width: 768px) {
  section {
    /* margin: 20px; */
    /* padding: 2rem; */
    /* background-color: var(--bg-color); */
    /* border-bottom: 5px solid var(--titles-color); */
    margin: 0 !important;
  }
}