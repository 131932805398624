.scrollToTopButton {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: var(--text-color);
    color: white;
    font-weight: bold;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    cursor: pointer;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, opacity 0.3s, visibility 0.3s;
    opacity: 0;
    visibility: hidden;
  }
  
  .scrollToTopButton:hover {
    background-color: var(--text-color);
  }
  
  .scrollToTopButton:active {
    background-color: var(--text-color);
  }
  
  .scrollToTopButton.visible {
    opacity: 1;
    visibility: visible;
  }
  
  .scrollToTopButton.hidden {
    opacity: 0;
    visibility: hidden;
  }
  