.header-div {
  font-family: "Poppins";
}

nav {
  width: 100%;
  background-color: var(--titles-color);
  color: var(--bg-color);
  padding: 1.1rem 0;
  text-align: center;
}

nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  margin: 0 10rem;
}

nav li {
  display: inline-block;
  margin-right: 10px;
  position: relative; /* Required for the underline */
}

nav a {
  color: var(--bg-color);
  text-decoration: none;
  position: relative;
  padding: 0.5rem 0; /* Add some padding for better spacing */
}

nav a::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: var(--bg-color-hover);
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease-in-out;
}

nav a:hover::after {
  transform: scaleX(1);
  height: 2px;

}

@media (max-width: 768px) {
  nav ul {
    margin: 0 1rem !important;
  }
  .navList {
    margin: 0 2rem;
    flex-direction: column;
    align-items: center;
  }

  .navItem {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .navItem:last-child {
    margin-bottom: 0;
  }
}
