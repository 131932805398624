.container {
    position: relative;
    width: 100%;
    height: 700px;
    overflow: hidden;
  }
  
  .image {
    width: 100%;
    height: 700px;
    object-fit: cover;
  }
  
  .text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    text-align: center;
    font-size: 40px;
    font-family: 'Poppins', sans-serif;
    color: white;
  }
  
 