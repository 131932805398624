/* ImageGallery.module.css */
.image-gallery-container {
    position: relative;
    width: 100%;
    max-width: 600px; /* Adjust max-width as needed */
    margin: auto;
    text-align: center;
    margin: 3rem 0;
    border: var(--text-color) 5px solid;
    border-radius: 0.5rem;
  }
  
  /* .gallery-image {
    max-width: 100%;
    height: auto;
  } */

  .gallery-image {
    width: 100%; /* Set a fixed width */
    height: 500px; /* Set a fixed height */
    object-fit: cover; /* Maintain aspect ratio */
  }
  
  .prev-button, .next-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    /* background-color: rgba(0, 0, 0, 0.5); */
    background-color: transparent;
    color: white;
    border: none;
    cursor: pointer;
    padding: 10px 15px;
    border-radius: 5px;
    height: 100%;
  }

  .prev-button:hover, .next-button:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .prev-button {
    left: 0;
  }
  
  .next-button {
    right: 0;
  }
  
  /* Media query for smaller screens (e.g., mobile devices) */
  @media (max-width: 600px) {
    .prev-button, .next-button {
      font-size: 12px;
      padding: 8px 10px;
    }
  }
  